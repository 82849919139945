import React, { useEffect } from 'react'
import SeminarTable from '../../components/SeminarTable/SeminarTable'
import DashboardLayout from '../../components/DashboardLayout/DashboardLayout'
import { useNavigate } from 'react-router-dom';

const Seminars = () => {
    const navigate = useNavigate();

  useEffect(() => {
    // Check if token exists in localStorage
    if (!localStorage.getItem('authToken')) {
      navigate('/home'); // Redirect to home page
    }
  }, [navigate]);
    return (
        <DashboardLayout>
            <SeminarTable />
        </DashboardLayout>
    )
}

export default Seminars