import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Switch from '@mui/material/Switch'; // Import the toggle switch from MUI

const AnnouncementFormModal = ({ closeModal, onSubmit, initialValues }) => {
    const [announcement, setAnnouncement] = useState(''); // state for announcement content

    const AnnouncementSchema = Yup.object().shape({
        heading: Yup.string().required('Heading is required'),
        description: Yup.string().required('description is required'),
    });

    const defaultValues = {
        heading: initialValues?.heading || '',
        description: initialValues?.description || '',
        isActive: initialValues?.isActive || false, // Set the default value for isActive
    };

    console.log("announcement", announcement);
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-2/4">
                <h2 className="text-xl font-bold mb-4">{initialValues ? 'Update Announcement' : 'Create Announcement'}</h2>

                <Formik
                    initialValues={defaultValues}
                    validationSchema={AnnouncementSchema}
                    onSubmit={(values) => {
                        onSubmit(values);
                        closeModal();
                    }}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            {/* Heading Field */}
                            <div className="mb-4">
                                <label className="block mb-1 text-sm font-medium">Heading</label>
                                <Field
                                    name="heading"
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-300 outline-none"
                                />
                                <ErrorMessage name="heading" component="div" className="text-red-500 text-sm mt-1" />
                            </div>

                            {/* Description Field */}
                            <div className="mb-4">
                                <label className="block mb-1 text-sm font-medium">Description</label>
                                <ReactQuill
                                    value={values.description}
                                    onChange={(content) => setFieldValue('description', content)}
                                    placeholder="Write your announcement here..."
                                    theme="snow" // 'snow' is the default theme
                                />
                                <ErrorMessage name="description" component="div" className="text-red-500 text-sm mt-1" />
                            </div>

                            {/* Active Status Toggle */}
                            <div className="mb-4 flex items-center">
                                <label className="block mb-1 text-sm font-medium mr-4">Active Status</label>
                                <Switch
                                    checked={values.isActive}
                                    onChange={(e) => setFieldValue('isActive', e.target.checked)}
                                    color="primary"
                                />
                                <span>{values.isActive ? 'Active' : 'Inactive'}</span>
                            </div>

                            {/* Buttons */}
                            <div className="flex justify-end space-x-2">
                                <button
                                    type="button"
                                    className="px-4 py-2 bg-gray-400 text-white rounded-md hover:bg-gray-500 transition"
                                    onClick={closeModal}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
                                >
                                    {initialValues ? 'Update' : 'Create'}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default AnnouncementFormModal;
