import React, { useEffect } from 'react';
import DashboardLayout from '../../components/DashboardLayout/DashboardLayout';
import JobTable from '../../components/JobOpeningTable/JobOpeningTable';
import { useNavigate } from 'react-router-dom';

const JobOpeningsPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if token exists in localStorage
    if (!localStorage.getItem('authToken')) {
      navigate('/home'); // Redirect to home page
    }
  }, [navigate]);
  return (
    <DashboardLayout>
      <JobTable />
    </DashboardLayout>
  );
};

export default JobOpeningsPage;